<router>
{
    name: 'Sales Tool Parts',
}
</router>
<template lang="pug">
v-container(fluid)
    v-tabs(v-model='tab', color='brand', light)
        v-tab MMLs
        v-tab Jonas Parts Import
        v-tab Custom Part Descriptions
        v-tab Part Dependencies
    v-tabs-items(v-model='tab', light)
        v-tab-item
            MMLs
        v-tab-item
            v-container
                PartCategories
        v-tab-item
            CustomPartDescriptions
        v-tab-item
            PartDependencyManagement
</template>
<script>

import PartCategories from '@/components/salestool/partcategories.vue';
import MMLs from '@/components/salestool/mmls.vue';
import CustomPartDescriptions from '@/components/salestool/custompartdescriptions.vue';
import PartDependencyManagement from '@/components/salestool/partdependencymanagement.vue';
export default {
    meta: {
        role: 'sat'
    },
    components: {
        PartCategories,
        MMLs,
        CustomPartDescriptions,
        PartDependencyManagement
    },
    data () {
        return {
            tab: 0
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
